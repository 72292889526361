@import 'utils/includes.scss';

.TextLink {
    @include smooth-transition();
    text-decoration: underline;
    line-height: 1em;
    cursor: pointer;
}

.TextLink-default {
    color: rgba(0, 0, 255, 0.64);
    &:hover {
        color: rgba(0, 0, 255, 0.94);
    }
}

.TextLink-primary {
    color: $white;
    &:active {
    }
}

.TextLink-secondary {
    color: $white;
    &:active {
    }
}

.TextLink-success {
    color: $white;
    &:active {
    }
}

.TextLink-alert {
    color: $white;
    &:active {
    }
}
