@mixin input-focus() {
  &:hover, &:focus {
    outline: none;
    box-shadow: 0 0 3px $primary-300;
    border-color: $primary-300;
  }
}
@mixin smooth-transition {
    transition: all 0.15s ease;
}
