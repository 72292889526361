$white: #ffffff;
$black: #000000;

$primary-000: rgba(0, 0, 0, 0.02);
$primary-100: rgba(0, 0, 0, 0.08);
$primary-200: rgba(0, 0, 0, 0.54);
$primary-300: rgba(0, 0, 0, 0.84);
$primary-400: rgba(0, 0, 0, 0.94);
$primary-500: rgba(0, 0, 0, 1);

$secondary-100: #1d9bff;
$secondary-200: #0390ff;
$secondary-300: #0082e9;
$secondary-400: #0074d0;
$secondary-500: #0066b6;

$success-100: #4be18a;
$success-200: #24d66e;
$success-300: #20c063;
$success-400: #1caa58;
$success-500: #157e41;

$alert-100: #fa8871;
$alert-200: #f97358;
$alert-300: #f74a27;
$alert-400: #e32d08;
$alert-500: #ca2807;